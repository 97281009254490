import type {ExecuteActionResponseADto} from '@cohort/admin-schemas/apps';
import type {SyncConfigComponentProps} from '@cohort/merchants/apps';
import UserPropertyColumnsTable from '@cohort/merchants/apps/postgresql/sync/UserPropertyColumnsTable';
import type {PostgresqlUserPropertyColumnWithSampleData} from '@cohort/merchants/apps/postgresql/sync/utils';
import {
  checkIfTestQueryError,
  getUserPropertyColumnsFromRows,
} from '@cohort/merchants/apps/postgresql/sync/utils';
import Button from '@cohort/merchants/components/buttons/Button';
import HighlightText from '@cohort/merchants/components/HighlightText';
import {InformationTooltip} from '@cohort/merchants/components/InformationTooltip';
import SectionSeparator from '@cohort/merchants/components/SectionSeparator';
import {useCohortMutation} from '@cohort/merchants/hooks/api/Query';
import {useUserSessionStore} from '@cohort/merchants/hooks/stores/userSession';
import {notify} from '@cohort/merchants/hooks/toast';
import {executeAction} from '@cohort/merchants/lib/api/Apps';
import type {UserSyncConfigFormValues} from '@cohort/merchants/pages/apps/app/utils';
import type {TestQueryActionStruct} from '@cohort/shared/apps/postgresql/actions/testQuery';
import {PostgresqlSyncConfigSchema} from '@cohort/shared/apps/postgresql/sync';
import {defaultNetworkErrorMessage} from '@cohort/shared/models';
import {cn} from '@cohort/shared-frontend/utils/classNames';
import Editor from '@monaco-editor/react';
import {Lightning} from '@phosphor-icons/react';
import {isEqual} from 'lodash';
import type {editor} from 'monaco-editor';
import {Fragment, useRef, useState} from 'react';
import {useFormContext} from 'react-hook-form';
import {useTranslation} from 'react-i18next';

const ImportUsersConfig: React.FC<SyncConfigComponentProps> = ({connection}) => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const merchantId = useUserSessionStore(store => store.merchantId!);
  const [isEditorReady, setIsEditorReady] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [testQueryUserPropertyColumns, setTestQueryUserPropertyColumns] = useState<
    PostgresqlUserPropertyColumnWithSampleData[]
  >([]);
  const [isRunQueryRequired, setIsRunQueryRequired] = useState(false);

  const {setValue, watch} =
    useFormContext<Extract<UserSyncConfigFormValues, {appId: 'postgresql'}>>();
  const editorRef = useRef<editor.IStandaloneCodeEditor>();

  const isImportEnabled = watch('userImportEnabled');
  const {query: configuredQuery, userPropertyColumns: configuredUserPropertyColumns} = watch(
    'userSyncConfig'
  ) ?? {
    query: '',
    userPropertyColumns: [],
  };

  const {t} = useTranslation('app-postgresql', {
    keyPrefix: 'sync.importUsersConfig',
  });

  const {mutate: testQuery, isLoading: isTestQueryLoading} = useCohortMutation({
    mutationFn: async (data: {
      query: string;
      merchantConnectionId: string;
    }): Promise<ExecuteActionResponseADto<TestQueryActionStruct>> =>
      executeAction<TestQueryActionStruct>(merchantId, {
        appId: 'postgresql',
        actionId: 'test-query',
        merchantConnectionId: data.merchantConnectionId,
        input: {query: data.query},
      }),
    onSuccess: (data, {query}) => {
      // i18nOwl-ignore [errors.invalidQuery, errors.noRowsReturned]
      // i18nOwl-ignore [errors.noEmailOrInvalidEmail, errors.noUpdatedAtOrInvalidDate]
      const errorMessage = checkIfTestQueryError(data, t);
      if (errorMessage !== null) {
        setError(errorMessage);
        setValue('userSyncConfig.query', '');
        setTestQueryUserPropertyColumns([]);

        return;
      }

      const userPropertyColumns = getUserPropertyColumnsFromRows(data.output.rows);
      const config = {
        query,
        userPropertyColumns: userPropertyColumns.map(({sampleData, ...rest}) => rest),
        emailColumn: 'email',
      };
      setError(null);
      setTestQueryUserPropertyColumns(userPropertyColumns);
      setValue('userSyncConfig', config);
      setIsRunQueryRequired(false);
    },
    onError: error => {
      notify('error', error instanceof Error ? error.message : defaultNetworkErrorMessage);

      setError(null);
      setTestQueryUserPropertyColumns([]);
    },
  });

  const getEditorValue = (): string | undefined => {
    return editorRef.current?.getValue();
  };

  const handleEditorDidMount = (editor: editor.IStandaloneCodeEditor): void => {
    setIsEditorReady(true);
    editorRef.current = editor;
  };

  const userSyncConfig = connection.sync?.userSyncConfig
    ? PostgresqlSyncConfigSchema.parse(connection.sync.userSyncConfig)
    : undefined;

  const userPropertyToDeleteColumns =
    userSyncConfig?.userPropertyColumns.filter(
      column =>
        !testQueryUserPropertyColumns.some(
          testQueryColumn => testQueryColumn.referenceId === column.referenceId
        )
    ) ?? [];

  return (
    <Fragment>
      <h2 className="text-lg font-semibold leading-6">{t('titleSqlQuery')}</h2>
      <div className="flex flex-col space-y-4">
        <HighlightText
          type="info"
          text={
            <div>
              <p>{t('instructions.mandatoryFields')}</p>
              <ul className="list-disc pl-6">
                <li>{t('instructions.emailField')}</li>
                <li>{t('instructions.updatedAtField')}</li>
              </ul>
            </div>
          }
        />
        {error && <HighlightText type="error" text={error} />}
        <Editor
          theme="vs-dark"
          defaultLanguage="sql"
          defaultValue={configuredQuery !== '' ? configuredQuery : 'SELECT'}
          height={150}
          onMount={handleEditorDidMount}
          onChange={value => {
            setIsRunQueryRequired(value !== configuredQuery);
          }}
          className={cn(!isImportEnabled ? 'opacity-50' : '')}
          options={{
            readOnly: !isImportEnabled,
            minimap: {enabled: false},
            padding: {top: 10, bottom: 10},
            scrollbar: {vertical: 'hidden'},
          }}
        />
        <div className="flew-row flex gap-2">
          <Button
            variant="secondary"
            onClick={() => {
              const value = getEditorValue();
              testQuery({query: value ?? '', merchantConnectionId: connection.id});
            }}
            loading={isTestQueryLoading}
            disabled={!isEditorReady || !isRunQueryRequired}
          >
            <Lightning size={20} className="mr-2" />
            {t('runQueryButton')}
          </Button>
          <InformationTooltip content={t('tooltipRunQuery')} side="right" />
        </div>
      </div>
      <SectionSeparator />
      {userSyncConfig === undefined ||
      !isEqual(userSyncConfig.userPropertyColumns, configuredUserPropertyColumns) ? (
        <Fragment>
          <h2 className="text-lg font-semibold leading-6">{t('titlePropertiesToSync')}</h2>
          <UserPropertyColumnsTable
            userPropertyColumns={testQueryUserPropertyColumns}
            isQueryConfigured={error === null && configuredUserPropertyColumns.length === 0}
          />
          {error === null && userPropertyToDeleteColumns.length > 0 && (
            <Fragment>
              <h2 className="text-lg font-semibold leading-6">{t('titlePropertiesToDelete')}</h2>
              <UserPropertyColumnsTable
                userPropertyColumns={userPropertyToDeleteColumns}
                hideSampleData
              />
            </Fragment>
          )}
        </Fragment>
      ) : (
        <Fragment>
          <h2 className="text-lg font-semibold leading-6">{t('titleSyncedProperties')}</h2>
          <UserPropertyColumnsTable
            userPropertyColumns={userSyncConfig.userPropertyColumns}
            hideSampleData
            isQueryConfigured
          />
        </Fragment>
      )}
    </Fragment>
  );
};

export default ImportUsersConfig;
