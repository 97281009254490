import {useTestModeStore} from '@cohort/merchants/hooks/stores/testMode';
import type {AppId} from '@cohort/shared/apps';

export const OAUTH_CALLBACK_PATH = 'oauth/callback';

export type PageName =
  | 'app-connections'
  | 'app-connection-new'
  | 'app-connection-edit'
  | 'app-connection-sync'
  | 'apps'
  | 'badge'
  | 'badges'
  | 'badge-edit'
  | 'badge-appearance'
  | 'badge-assigned-cohort'
  | 'badge-notifications'
  | 'badge-visibility'
  | 'brand'
  | 'campaign-activity'
  | 'campaign-audience'
  | 'campaign-audience-edit'
  | 'campaign-notifications'
  | 'campaign-edit'
  | 'campaign-orders'
  | 'campaign-perks'
  | 'campaign-redeem-links'
  | 'campaign-reward'
  | 'campaign-reward-edit'
  | 'campaign-settings'
  | 'campaign'
  | 'campaignPreview'
  | 'campaigns'
  | 'change-password'
  | 'contents'
  | 'content'
  | 'content-categories'
  | 'content-edit'
  | 'content-activity'
  | 'content-audience'
  | 'content-audience-edit'
  | 'content-notifications'
  | 'content-settings'
  | 'cohort-members'
  | 'cohort-rule'
  | 'cohorts'
  | 'customization'
  | 'digital-asset-campaigns'
  | 'digital-asset-notifications'
  | 'digital-asset-owners'
  | 'digital-asset-perks'
  | 'digital-asset-settings'
  | 'digital-asset'
  | 'digital-assets'
  | 'home'
  | 'integrations'
  | 'invitation'
  | 'languages'
  | 'login'
  | 'membership-pass-configuration'
  | 'notifications'
  | 'new'
  | 'org-picker'
  | 'payment'
  | 'perk-campaigns'
  | 'perk-create'
  | 'perk-digital-assets'
  | 'perk-edit'
  | 'perk-notifications'
  | 'perk-owners'
  | 'perk'
  | 'perks'
  | 'qr-readers'
  | 'forgot-password'
  | 'settings'
  | 'signin'
  | 'team'
  | 'user'
  | 'user-activity'
  | 'user-badges'
  | 'user-cohorts'
  | 'user-digital-assets'
  | 'user-challenges'
  | 'user-overview'
  | 'user-perks'
  | 'user-properties'
  | 'user-property'
  | 'user-property'
  | 'users';

export interface PageLink {
  path: string;
  name: PageName;
}

export const getTestModePrefix = (): string => {
  const testModeEnabled = useTestModeStore.getState().testModeEnabled;
  return testModeEnabled ? '/test' : '';
};

export const getBadgesRoute = (): PageLink => {
  return {
    path: `${getTestModePrefix()}/badges`,
    name: 'badges',
  };
};

export const getBadgeRoute = (badgeId: string): PageLink => {
  return {
    path: `${getTestModePrefix()}/badges/${badgeId}`,
    name: 'badge',
  };
};

export const getBadgeEditRoute = (badgeId: string): PageLink => {
  return {
    path: `${getTestModePrefix()}/badges/${badgeId}/edit`,
    name: 'badge-edit',
  };
};

export const getBadgeAppearanceRoute = (badgeId: string): PageLink => {
  return {
    path: `${getTestModePrefix()}/badges/${badgeId}/appearance`,
    name: 'badge-appearance',
  };
};

export const getBadgeCohortRoute = (badgeId: string): PageLink => {
  return {
    path: `${getTestModePrefix()}/badges/${badgeId}/cohort`,
    name: 'badge-assigned-cohort',
  };
};

export const getBadgeNotificationsRoute = (badgeId: string): PageLink => {
  return {
    path: `${getTestModePrefix()}/badges/${badgeId}/notifications`,
    name: 'badge-notifications',
  };
};

export const getBadgeVisibilityRoute = (badgeId: string): PageLink => {
  return {
    path: `${getTestModePrefix()}/badges/${badgeId}/visibility`,
    name: 'badge-visibility',
  };
};

export const getBrandRoute = (): PageLink => {
  return {
    path: `${getTestModePrefix()}/settings/brand`,
    name: 'brand',
  };
};

export const getCustomizationRoute = (): PageLink => {
  return {
    path: `${getTestModePrefix()}/settings/customization`,
    name: 'customization',
  };
};

export const getCampaignRoute = (campaignId: string): PageLink => {
  return {
    path: `${getTestModePrefix()}/campaigns/${campaignId}`,
    name: 'campaign',
  };
};

export const getCampaignEditRoute = (campaignId: string): PageLink => {
  return {
    path: `${getTestModePrefix()}/campaigns/${campaignId}/edit`,
    name: 'campaign-edit',
  };
};

export const getCampaignOrdersRoute = (campaignId: string): PageLink => {
  return {
    path: `${getTestModePrefix()}/campaigns/${campaignId}/orders`,
    name: 'campaign-orders',
  };
};

export const getCampaignActivityRoute = (campaignId: string): PageLink => {
  return {
    path: `${getTestModePrefix()}/campaigns/${campaignId}/activity`,
    name: 'campaign-activity',
  };
};

export const getCampaignAudienceRoute = (campaignId: string): PageLink => {
  return {
    path: `${getTestModePrefix()}/campaigns/${campaignId}/audience`,
    name: 'campaign-audience',
  };
};

export const getCampaignAudienceEditRoute = (campaignId: string): PageLink => {
  return {
    path: `${getTestModePrefix()}/campaigns/${campaignId}/audience/edit`,
    name: 'campaign-audience-edit',
  };
};

export const getCampaignNotificationRoute = (campaignId: string): PageLink => {
  return {
    path: `${getTestModePrefix()}/campaigns/${campaignId}/notifications`,
    name: 'campaign-notifications',
  };
};

export const getCampaignRewardRoute = (campaignId: string): PageLink => {
  return {
    path: `${getTestModePrefix()}/campaigns/${campaignId}/reward`,
    name: 'campaign-reward',
  };
};

export const getCampaignRedeemLinksRoute = (campaignId: string): PageLink => {
  return {
    path: `${getTestModePrefix()}/campaigns/${campaignId}/redeem-links`,
    name: 'campaign-redeem-links',
  };
};

export const getCampaignPerksRoute = (campaignId: string): PageLink => {
  return {
    path: `${getTestModePrefix()}/campaigns/${campaignId}/perks`,
    name: 'campaign-perks',
  };
};

export const getCampaignSettingsRoute = (campaignId: string): PageLink => {
  return {
    path: `${getTestModePrefix()}/campaigns/${campaignId}/settings`,
    name: 'campaign-settings',
  };
};

export const getCampaignsRoute = (): PageLink => {
  return {
    path: `${getTestModePrefix()}/campaigns`,
    name: 'campaigns',
  };
};

export const getContentCategoriesRoute = (): PageLink => {
  return {
    path: `${getTestModePrefix()}/content-categories`,
    name: 'content-categories',
  };
};

export const getContentsRoute = (): PageLink => {
  return {
    path: `${getTestModePrefix()}/contents`,
    name: 'contents',
  };
};

export const getContentRoute = (contentId: string): PageLink => {
  return {
    path: `${getTestModePrefix()}/contents/${contentId}`,
    name: 'content',
  };
};

export const getContentEditRoute = (contentId: string): PageLink => {
  return {
    path: `${getTestModePrefix()}/contents/${contentId}/edit`,
    name: 'content-edit',
  };
};

export const getContentActivityRoute = (contentId: string): PageLink => {
  return {
    path: `${getTestModePrefix()}/contents/${contentId}/activity`,
    name: 'content-activity',
  };
};

export const getContentAudienceRoute = (contentId: string): PageLink => {
  return {
    path: `${getTestModePrefix()}/contents/${contentId}/audience`,
    name: 'content-audience',
  };
};

export const getContentAudienceEditRoute = (contentId: string): PageLink => {
  return {
    path: `${getTestModePrefix()}/contents/${contentId}/audience/edit`,
    name: 'content-audience-edit',
  };
};

export const getContentNotificationRoute = (contentId: string): PageLink => {
  return {
    path: `${getTestModePrefix()}/contents/${contentId}/notifications`,
    name: 'content-notifications',
  };
};

export const getContentSettingsRoute = (contentId: string): PageLink => {
  return {
    path: `${getTestModePrefix()}/contents/${contentId}/settings`,
    name: 'content-settings',
  };
};

export const getDigitalAssetRoute = (digitalAssetCollectionId: string): PageLink => {
  return {
    path: `${getTestModePrefix()}/digital-assets/${digitalAssetCollectionId}`,
    name: 'digital-asset',
  };
};

export const getDigitalAssetSettingsRoute = (digitalAssetCollectionId: string): PageLink => {
  return {
    path: `${getTestModePrefix()}/digital-assets/${digitalAssetCollectionId}/settings`,
    name: 'digital-asset-settings',
  };
};

export const getDigitalAssetCampaignsRoute = (digitalAssetCollectionId: string): PageLink => {
  return {
    path: `${getTestModePrefix()}/digital-assets/${digitalAssetCollectionId}/campaigns`,
    name: 'digital-asset-campaigns',
  };
};

export const getDigitalAssetNotificationsRoute = (digitalAssetCollectionId: string): PageLink => {
  return {
    path: `${getTestModePrefix()}/digital-assets/${digitalAssetCollectionId}/notifications`,
    name: 'digital-asset-notifications',
  };
};

export const getDigitalAssetOwnersRoute = (digitalAssetCollectionId: string): PageLink => {
  return {
    path: `${getTestModePrefix()}/digital-assets/${digitalAssetCollectionId}/owners`,
    name: 'digital-asset-owners',
  };
};

export const getDigitalAssetsRoute = (): PageLink => {
  return {
    path: `${getTestModePrefix()}/digital-assets`,
    name: 'digital-assets',
  };
};

export const getHomeRoute = (): PageLink => {
  return {
    path: `${getTestModePrefix()}/campaigns`,
    name: 'home',
  };
};

export const getInvitationRoute = (): PageLink => {
  return {
    path: `${getTestModePrefix()}/invitation`,
    name: 'invitation',
  };
};

export const getNewOrgRoute = (): PageLink => {
  return {
    path: '/new',
    name: 'new',
  };
};

export const getPerkCampaignsRoute = (perkId: string): PageLink => {
  return {
    path: `${getTestModePrefix()}/perks/${perkId}/campaigns`,
    name: 'perk-campaigns',
  };
};

export const getPerkCreateRoute = (): PageLink => {
  return {
    path: `${getTestModePrefix()}/perks/new`,
    name: 'perk-create',
  };
};

export const getPerkRoute = (perkId: string): PageLink => {
  return {
    path: `${getTestModePrefix()}/perks/${perkId}`,
    name: 'perk-edit',
  };
};

export const getPerkEditRoute = (perkId: string): PageLink => {
  return {
    path: `${getTestModePrefix()}/perks/${perkId}/edit`,
    name: 'perk-edit',
  };
};

export const getPerkNotificationsRoute = (perkId: string): PageLink => {
  return {
    path: `${getTestModePrefix()}/perks/${perkId}/notifications`,
    name: 'perk-notifications',
  };
};

export const getPerkOwersRoute = (perkId: string): PageLink => {
  return {
    path: `${getTestModePrefix()}/perks/${perkId}/owners`,
    name: 'perk-owners',
  };
};

export const getPerkDigitalAssetsRoute = (perkId: string): PageLink => {
  return {
    path: `${getTestModePrefix()}/perks/${perkId}/digital-assets`,
    name: 'perk-digital-assets',
  };
};

export const getPerksRoute = (): PageLink => {
  return {
    path: `${getTestModePrefix()}/perks`,
    name: 'perks',
  };
};

export const getSignInRoute = (): PageLink => {
  return {
    path: '/sign-in',
    name: 'signin',
  };
};

export const getForgotPasswordRoute = (): PageLink => {
  return {
    path: '/forgot-password',
    name: 'forgot-password',
  };
};

export const getChangePasswordRoute = (): PageLink => {
  return {
    path: '/change-password',
    name: 'change-password',
  };
};

export const getSettingsRoute = (): PageLink => {
  return {
    path: `${getTestModePrefix()}/settings`,
    name: 'settings',
  };
};

export const getPaymentSettingsRoute = (): PageLink => {
  return {
    path: `${getTestModePrefix()}/settings/payments`,
    name: 'payment',
  };
};

export const getMembershipPassConfigurationRoute = (): PageLink => {
  return {
    path: `${getTestModePrefix()}/settings/membership-pass-configuration`,
    name: 'membership-pass-configuration',
  };
};

export const getAppsRoute = (): PageLink => {
  return {
    path: `${getTestModePrefix()}/settings/apps`,
    name: 'apps',
  };
};

export const getAppConnectionsRoute = (appId: AppId): PageLink => {
  return {
    path: `${getTestModePrefix()}/apps/${appId}/connections`,
    name: 'app-connections',
  };
};

export const getAppConnectionNewRoute = (appId: AppId): PageLink => {
  return {
    path: `${getTestModePrefix()}/apps/${appId}/connections/new`,
    name: 'app-connection-new',
  };
};

export const getAppConnectionEditRoute = (appId: AppId, connectionId: string): PageLink => {
  return {
    path: `${getTestModePrefix()}/apps/${appId}/connections/${connectionId}/edit`,
    name: 'app-connection-edit',
  };
};

export const getAppConnectionSyncRoute = (appId: AppId, connectionId: string): PageLink => {
  return {
    path: `${getTestModePrefix()}/apps/${appId}/connections/${connectionId}/sync`,
    name: 'app-connection-sync',
  };
};

export const getIntegrationsSettingsRoute = (): PageLink => {
  return {
    path: `${getTestModePrefix()}/settings/integrations`,
    name: 'integrations',
  };
};

export const getNotificationsSettingsRoute = (): PageLink => {
  return {
    path: `${getTestModePrefix()}/settings/notifications`,
    name: 'notifications',
  };
};

export const getQrReadersSettingsRoute = (): PageLink => {
  return {
    path: `${getTestModePrefix()}/settings/qr-readers`,
    name: 'qr-readers',
  };
};

export const getUserPropertiesSettingsRoute = (): PageLink => {
  return {
    path: `${getTestModePrefix()}/settings/user-properties`,
    name: 'user-properties',
  };
};

export const getUserPropertySettingsRoute = (userPropertyId: string): PageLink => {
  return {
    path: `${getTestModePrefix()}/settings/user-properties/${userPropertyId}`,
    name: 'user-property',
  };
};

export const getTeamRoute = (): PageLink => {
  return {
    path: `${getTestModePrefix()}/settings/team`,
    name: 'team',
  };
};

export const getLanguagesRoute = (): PageLink => {
  return {
    path: `${getTestModePrefix()}/settings/languages`,
    name: 'languages',
  };
};

export const getUserRoute = (userId: string): PageLink => {
  return {
    path: `${getTestModePrefix()}/users/${userId}`,
    name: 'user',
  };
};

export const getUserActivityRoute = (userId: string): PageLink => {
  return {
    path: `${getTestModePrefix()}/users/${userId}/activity`,
    name: 'user-activity',
  };
};

export const getUserDigitalAssetsRoute = (userId: string): PageLink => {
  return {
    path: `${getTestModePrefix()}/users/${userId}/digital-assets`,
    name: 'user-digital-assets',
  };
};

export const getUserChallengesRoute = (userId: string): PageLink => {
  return {
    path: `${getTestModePrefix()}/users/${userId}/challenges`,
    name: 'user-challenges',
  };
};

export const getUserOverviewRoute = (userId: string): PageLink => {
  return {
    path: `${getTestModePrefix()}/users/${userId}/overview`,
    name: 'user-overview',
  };
};

export const getUserPerksRoute = (userId: string): PageLink => {
  return {
    path: `${getTestModePrefix()}/users/${userId}/perks`,
    name: 'user-perks',
  };
};

export const getUserCohortsRoute = (userId: string): PageLink => {
  return {
    path: `${getTestModePrefix()}/users/${userId}/cohorts`,
    name: 'user-cohorts',
  };
};

export const getUserBadgesRoute = (userId: string): PageLink => {
  return {
    path: `${getTestModePrefix()}/users/${userId}/badges`,
    name: 'user-badges',
  };
};

export const getUsersRoute = (): PageLink => {
  return {
    path: `${getTestModePrefix()}/users`,
    name: 'users',
  };
};

export const getCohortsRoute = (): PageLink => {
  return {
    path: `${getTestModePrefix()}/cohorts`,
    name: 'cohorts',
  };
};

export const getCohortMembersRoute = (cohortId: string): PageLink => {
  return {
    path: `${getTestModePrefix()}/cohorts/${cohortId}/members`,
    name: 'cohort-members',
  };
};

export const getCohortRuleRoute = (cohortId: string): PageLink => {
  return {
    path: `${getTestModePrefix()}/cohorts/${cohortId}/rule`,
    name: 'cohort-rule',
  };
};

export const getOrgPickerRoute = (): PageLink => {
  return {
    path: `/sign-in/organizations`,
    name: 'org-picker',
  };
};
