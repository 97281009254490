import {AppIdSchema} from '@cohort/shared/apps';
import {BaseUserEventExportConfigSchema} from '@cohort/shared/apps/sync';
import {CronSchema, DatetimeSchema} from '@cohort/shared/schema/common';
import {SyncStatusSchema} from '@cohort/shared/schema/common/sync';
import {z} from 'zod';

export const SyncASchema = z.object({
  id: z.string().uuid(),
  connectionId: z.string().uuid(),
  appId: AppIdSchema,
  contentSyncConfig: z.unknown(),
  userSyncConfig: z.unknown(),
  userExportConfig: z.unknown(),
  schedule: CronSchema,
  status: SyncStatusSchema,
  lastRunAt: DatetimeSchema.nullable(),
  createdAt: DatetimeSchema,
  updatedAt: DatetimeSchema,
});
export type SyncADto = z.infer<typeof SyncASchema>;

// List Syncs

export const ListSyncsParamsASchema = z.object({
  appId: AppIdSchema.optional(),
});
export type ListSyncsParamsADto = z.infer<typeof ListSyncsParamsASchema>;

export const ListSyncsResponseASchema = z.array(SyncASchema);
export type ListSyncsResponseADto = z.infer<typeof ListSyncsResponseASchema>;

// Create Sync

export const CreateSyncDataASchema = z.object({
  connectionId: z.string().uuid(),
  appId: AppIdSchema,
  contentSyncConfig: z.unknown().optional(),
  userSyncConfig: z.unknown().optional(),
  userEventExportConfig: BaseUserEventExportConfigSchema.optional(),
  userExportConfig: z.unknown().optional(),
  schedule: CronSchema.optional(),
});
export type CreateSyncDataADto = z.infer<typeof CreateSyncDataASchema>;

// Update Sync

export const UpdateSyncDataASchema = z.object({
  contentSyncConfig: z.unknown().optional(),
  userSyncConfig: z.unknown().optional(),
  userEventExportConfig: BaseUserEventExportConfigSchema.optional(),
  userExportConfig: z.unknown().optional(),
  schedule: CronSchema.optional(),
});
export type UpdateSyncDataADto = z.infer<typeof UpdateSyncDataASchema>;
