import type {SalesforceUserObject} from '@cohort/merchants/apps/salesforce/sync/utils';
import {
  buildDefaultUserPropertyColumn,
  getEmailFieldsFromObject,
  getSalesforceObjectByName,
} from '@cohort/merchants/apps/salesforce/sync/utils';
import SelectInput from '@cohort/merchants/components/form/select/SelectInput';
import type {SelectOption} from '@cohort/merchants/components/form/select/SelectPicker';
import HighlightText from '@cohort/merchants/components/HighlightText';
import type {UserSyncConfigFormValues} from '@cohort/merchants/pages/apps/app/utils';
import {useFieldArray, useFormContext} from 'react-hook-form';
import {useTranslation} from 'react-i18next';

type ImportUsersObjectNameSelectProps = {
  salesforceUserObjects: SalesforceUserObject[];
};
const ImportUsersObjectNameSelect: React.FC<ImportUsersObjectNameSelectProps> = ({
  salesforceUserObjects,
}) => {
  const {setValue, register, control, watch} =
    useFormContext<Extract<UserSyncConfigFormValues, {appId: 'salesforce'}>>();

  const {t} = useTranslation('app-salesforce', {
    keyPrefix: 'sync.importUsersObjectNameSelect',
  });

  const isImportEnabled = watch('userImportEnabled');

  const {replace: replaceUserPropertyColumns} = useFieldArray({
    control,
    name: 'userSyncConfig.userPropertyColumns',
  });

  const objectNameOptions = salesforceUserObjects.map(object => ({
    label: object.label,
    value: object.name,
  }));

  const resetSelectedFields = (): void => {
    replaceUserPropertyColumns([buildDefaultUserPropertyColumn()]);
  };

  const handleChange = (option: SelectOption | null): void => {
    const updatedObject = getSalesforceObjectByName(salesforceUserObjects, String(option?.value));

    if (updatedObject === undefined) {
      return;
    }

    resetSelectedFields();
    setValue('userSyncConfig.salesforceObjectName', updatedObject.name);

    const emailFields = getEmailFieldsFromObject(updatedObject);
    if (emailFields[0] === undefined) {
      return;
    }

    setValue('userSyncConfig.emailFieldName', emailFields[0].name);
  };

  return (
    <div className="flex flex-col gap-2">
      {isImportEnabled && salesforceUserObjects.length === 0 && (
        <HighlightText
          type="warning"
          text={
            <div>
              <p>{t('noSalesforceUserObjects')}</p>
              <p>{t('pleaseContactAdmin')}</p>
            </div>
          }
        />
      )}
      <SelectInput
        name="userSyncConfig.salesforceObjectName"
        placeholder={t('placeholder')}
        register={register}
        control={control}
        label={t('label')}
        options={objectNameOptions}
        disabled={!isImportEnabled}
        onChange={handleChange}
      />
    </div>
  );
};

export default ImportUsersObjectNameSelect;
