import App from '@cohort/merchants/App';
import NotFoundPage from '@cohort/merchants/components/error-pages/NotFoundPage';
import NotFoundSection from '@cohort/merchants/components/error-pages/NotFoundSection';
import AuthLayout from '@cohort/merchants/layouts/AuthLayout';
import NoAuthLayout from '@cohort/merchants/layouts/NoAuthLayout';
import OrgPickerLayout from '@cohort/merchants/layouts/OrgPickerLayout';
import {getUsersRoute, OAUTH_CALLBACK_PATH} from '@cohort/merchants/lib/Pages';
import AppPageLayout from '@cohort/merchants/pages/apps/app/AppPageLayout';
import ConnectionPageLayout from '@cohort/merchants/pages/apps/app/connections/connection/ConnectionPageLayout';
import CampaignPageLayout from '@cohort/merchants/pages/campaigns/campaign/CampaignPageLayout';
import ContentPageLayout from '@cohort/merchants/pages/contents/content/ContentPageLayout';
import DigitalAssetCollectionPageLayout from '@cohort/merchants/pages/digital-asset-collections/digital-asset-collection/DigitalAssetCollectionPageLayout';
import PerkPageLayout from '@cohort/merchants/pages/perks/perk/PerkPageLayout';
import SettingsLayout from '@cohort/merchants/pages/settings/SettingsLayout';
import BadgePageLayout from '@cohort/merchants/pages/users/badges/BadgePageLayout';
import CohortPageLayout from '@cohort/merchants/pages/users/cohort/CohortPageLayout';
import UserPageLayout from '@cohort/merchants/pages/users/user/UserPageLayout';
import {wrapCreateBrowserRouter} from '@sentry/react';
import {createBrowserRouter, Navigate} from 'react-router-dom';

const sentryCreateBrowserRouter = wrapCreateBrowserRouter(createBrowserRouter);

const notAuthRoutes = [
  {index: true, element: <Navigate to="/sign-in" replace />},
  {path: 'sign-in', lazy: () => import('@cohort/merchants/pages/sign-in/page')},
  {
    path: OAUTH_CALLBACK_PATH,
    lazy: () => import('@cohort/merchants/pages/connections/OAuthCallbackModal'),
  },
];

const authRoutes = [
  {index: true, element: <Navigate to={getUsersRoute().path} replace />},
  {
    path: 'users',
    children: [
      {
        index: true,
        lazy: () => import('@cohort/merchants/pages/users/overview/page'),
      },
      {
        path: ':userId',
        element: <UserPageLayout />,
        children: [
          {
            index: true,
            element: <Navigate to="overview" replace />,
          },
          {
            path: 'activity',
            lazy: () => import('@cohort/merchants/pages/users/user/activity/page'),
          },
          {
            path: 'badges',
            lazy: () => import('@cohort/merchants/pages/users/user/badges/page'),
          },
          {
            path: 'cohorts',
            lazy: () => import('@cohort/merchants/pages/users/user/cohorts/page'),
          },
          {
            path: 'overview',
            lazy: () => import('@cohort/merchants/pages/users/user/overview/page'),
          },
          {
            path: 'digital-assets',
            lazy: () => import('@cohort/merchants/pages/users/user/digital-assets/page'),
          },
          {
            path: 'challenges',
            lazy: () => import('@cohort/merchants/pages/users/user/challenges/page'),
          },
          {
            path: 'perks',
            lazy: () => import('@cohort/merchants/pages/users/user/perks/page'),
          },
        ],
      },
      {
        path: '*',
        element: <NotFoundSection />,
      },
    ],
  },
  {
    path: 'badges',
    children: [
      {
        index: true,
        lazy: () => import('@cohort/merchants/pages/users/badges/page'),
      },
      {
        path: ':badgeId',
        element: <BadgePageLayout />,
        children: [
          {
            index: true,
            element: <Navigate to="appearance" replace />,
          },
          {
            path: 'appearance',
            lazy: () => import('@cohort/merchants/pages/users/badges/badge/appearance/page'),
          },
          {
            path: 'cohort',
            lazy: () => import('@cohort/merchants/pages/users/badges/badge/cohort/page'),
          },
          {
            path: 'edit',
            lazy: () => import('@cohort/merchants/pages/users/badges/badge/edit/page'),
          },
          {
            path: 'visibility',
            lazy: () => import('@cohort/merchants/pages/users/badges/badge/visibility/page'),
          },
          {
            path: 'notifications',
            lazy: () => import('@cohort/merchants/pages/users/badges/badge/notifications/page'),
          },
        ],
      },
      {
        path: '*',
        element: <NotFoundSection />,
      },
    ],
  },
  {
    path: 'cohorts',
    children: [
      {
        index: true,
        lazy: () => import('@cohort/merchants/pages/users/cohorts/page'),
      },
      {
        path: ':cohortId',
        element: <CohortPageLayout />,
        children: [
          {
            index: true,
            element: <Navigate to="members" replace />,
          },
          {
            path: 'members',
            lazy: () => import('@cohort/merchants/pages/users/cohort/members/page'),
          },
          {
            path: 'rule',
            lazy: () => import('@cohort/merchants/pages/users/cohort/rule/page'),
          },
        ],
      },
      {
        path: '*',
        element: <NotFoundSection />,
      },
    ],
  },
  {
    path: 'campaigns',
    children: [
      {
        index: true,
        lazy: () => import('@cohort/merchants/pages/campaigns/overview/page'),
      },
      {
        path: ':campaignId',
        element: <CampaignPageLayout />,
        children: [
          {
            index: true,
            element: <Navigate to="activity" replace />,
          },
          {
            path: 'activity',
            lazy: () => import('@cohort/merchants/pages/campaigns/campaign/activity/page'),
          },
          {
            path: 'audience',
            lazy: () => import('@cohort/merchants/pages/campaigns/campaign/audience/page'),
          },
          {
            path: 'audience/edit',
            lazy: () => import('@cohort/merchants/pages/campaigns/campaign/audience/edit/page'),
          },
          {
            path: 'edit',
            lazy: () => import('@cohort/merchants/pages/campaigns/campaign/edit/page'),
          },
          {
            path: 'notifications',
            lazy: () => import('@cohort/merchants/pages/campaigns/campaign/notifications/page'),
          },
          {
            path: 'reward',
            lazy: () => import('@cohort/merchants/pages/campaigns/campaign/reward/edit/page'),
          },
          {
            path: 'settings',
            lazy: () => import('@cohort/merchants/pages/campaigns/campaign/settings/page'),
          },
        ],
      },
      {
        path: '*',
        element: <NotFoundSection />,
      },
    ],
  },
  {
    path: 'contents',
    children: [
      {
        index: true,
        lazy: () => import('@cohort/merchants/pages/contents/overview/page'),
      },
      {
        path: ':contentId',
        element: <ContentPageLayout />,
        children: [
          {
            index: true,
            element: <Navigate to="activity" replace />,
          },
          {
            path: 'edit',
            lazy: () => import('@cohort/merchants/pages/contents/content/edit/page'),
          },
        ],
      },
    ],
  },
  {
    path: 'content-categories',
    children: [
      {
        index: true,
        lazy: () => import('@cohort/merchants/pages/content-categories/overview/page'),
      },
    ],
  },
  {
    path: 'perks',
    children: [
      {
        index: true,
        lazy: () => import('@cohort/merchants/pages/perks/overview/page'),
      },
      {
        path: 'new',
        lazy: () => import('@cohort/merchants/pages/perks/new/page'),
      },
      {
        path: ':perkId',
        element: <PerkPageLayout />,
        children: [
          {
            index: true,
            element: <Navigate to="owners" replace />,
          },
          {
            path: 'owners',
            lazy: () => import('@cohort/merchants/pages/perks/perk/owners/page'),
          },
          {
            path: 'campaigns',
            lazy: () => import('@cohort/merchants/pages/perks/perk/campaigns/page'),
          },
          {
            path: 'notifications',
            lazy: () => import('@cohort/merchants/pages/perks/perk/notifications/page'),
          },
          {
            path: 'edit',
            lazy: () => import('@cohort/merchants/pages/perks/perk/edit/page'),
          },
        ],
      },
      {
        path: '*',
        element: <NotFoundSection />,
      },
    ],
  },
  {
    path: 'digital-assets',
    children: [
      {
        index: true,
        lazy: () => import('@cohort/merchants/pages/digital-asset-collections/overview/page'),
      },
      {
        path: ':digitalAssetCollectionId',
        element: <DigitalAssetCollectionPageLayout />,
        children: [
          {
            index: true,
            element: <Navigate to="owners" replace />,
          },
          {
            path: 'owners',
            lazy: () =>
              import(
                '@cohort/merchants/pages/digital-asset-collections/digital-asset-collection/owners/page'
              ),
          },
          {
            path: 'campaigns',
            lazy: () =>
              import(
                '@cohort/merchants/pages/digital-asset-collections/digital-asset-collection/campaigns/page'
              ),
          },
          {
            path: 'notifications',
            lazy: () =>
              import(
                '@cohort/merchants/pages/digital-asset-collections/digital-asset-collection/notifications/page'
              ),
          },
          {
            path: 'settings',
            lazy: () =>
              import(
                '@cohort/merchants/pages/digital-asset-collections/digital-asset-collection/settings/page'
              ),
          },
        ],
      },
      {
        path: '*',
        element: <NotFoundSection />,
      },
    ],
  },
  {
    path: 'apps',
    children: [
      {
        index: true,
        element: <Navigate to="/settings/apps" replace />,
      },
      {
        path: ':appId/connections',
        element: <AppPageLayout />,
        children: [
          {
            index: true,
            lazy: () => import('@cohort/merchants/pages/apps/app/connections/page'),
          },
          {
            path: 'new',
            lazy: () => import('@cohort/merchants/pages/apps/app/connections/new/page'),
          },
        ],
      },
      {
        path: ':appId/connections/:connectionId',
        element: <ConnectionPageLayout />,
        children: [
          {
            path: 'edit',
            lazy: () => import('@cohort/merchants/pages/apps/app/connections/connection/edit/page'),
          },
          {
            path: 'sync',
            lazy: () => import('@cohort/merchants/pages/apps/app/connections/connection/sync/page'),
          },
        ],
      },
      {
        path: '*',
        element: <NotFoundSection />,
      },
    ],
  },
  {
    path: 'settings',
    element: <SettingsLayout />,
    children: [
      {
        index: true,
        element: <Navigate to="brand" replace />,
      },
      {
        path: 'brand',
        lazy: () => import('@cohort/merchants/pages/settings/brand/page'),
      },
      {
        path: 'customization',
        lazy: () => import('@cohort/merchants/pages/settings/customization/page'),
      },
      {
        path: 'apps',
        children: [
          {
            index: true,
            lazy: () => import('@cohort/merchants/pages/settings/apps/page'),
          },
        ],
      },
      {
        path: 'notifications',
        lazy: () => import('@cohort/merchants/pages/settings/notifications/page'),
      },
      {
        path: 'membership-pass-configuration',
        lazy: () => import('@cohort/merchants/pages/settings/membership-pass-configuration/page'),
      },
      {
        path: 'payments',
        lazy: () => import('@cohort/merchants/pages/settings/payments/page'),
      },
      {
        path: 'qr-readers',
        lazy: () => import('@cohort/merchants/pages/settings/qr-readers/page'),
      },
      {
        path: 'integrations',
        lazy: () => import('@cohort/merchants/pages/settings/integrations/page'),
      },
      {
        path: 'team',
        lazy: () => import('@cohort/merchants/pages/settings/members/page'),
      },
      {
        path: 'languages',
        lazy: () => import('@cohort/merchants/pages/settings/supported-languages/page'),
      },
      {
        path: 'user-properties',
        children: [
          {
            index: true,
            lazy: () => import('@cohort/merchants/pages/settings/user-properties/page'),
          },
          {
            path: ':userPropertyId',
            lazy: () =>
              import('@cohort/merchants/pages/settings/user-properties/UserPropertyDetailPage'),
          },
        ],
      },
      {
        path: '*',
        element: <NotFoundSection />,
      },
    ],
  },
];

const routes = sentryCreateBrowserRouter([
  {
    path: '/',
    element: <App />,
    children: [
      {
        element: <NoAuthLayout />,
        children: notAuthRoutes,
      },
      {
        path: '/invitation',
        lazy: () => import('@cohort/merchants/pages/invitation/page'),
      },
      {
        path: '/forgot-password',
        lazy: () => import('@cohort/merchants/pages/forgot-password/page'),
      },
      {
        path: '/change-password',
        lazy: () => import('@cohort/merchants/pages/change-password/page'),
      },
      // make the next line only in development
      ...(import.meta.env.COHORT_ENV === 'development'
        ? [
            {
              path: '/sentry',
              lazy: () => import('@cohort/merchants/pages/sentry/page'),
            },
          ]
        : []),
      {
        element: <AuthLayout />,
        children: [
          {
            path: '/',
            children: authRoutes,
          },
          {
            path: '/test',
            children: authRoutes,
          },
          // make the next line only in development
          ...(import.meta.env.COHORT_ENV === 'development'
            ? [
                {
                  path: '/analytics',
                  lazy: () => import('@cohort/merchants/pages/analytics/page'),
                },
              ]
            : []),
        ],
      },
      {
        element: <OrgPickerLayout />,
        children: [
          {
            path: 'sign-in/organizations',
            lazy: () => import('@cohort/merchants/pages/sign-in/org-picker/page'),
          },
        ],
      },
      {path: '*', element: <NotFoundPage />},
    ],
  },
]);

export default routes;
