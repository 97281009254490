import ExportUsersMappedUserProperty from '@cohort/merchants/apps/salesforce/sync/ExportUsersMappedUserProperty';
import type {
  MappedUserProperty,
  SalesforceUserObject,
} from '@cohort/merchants/apps/salesforce/sync/utils';
import {
  buildDefaultMappedUserProperty,
  getSalesforceObjectByName,
} from '@cohort/merchants/apps/salesforce/sync/utils';
import Button from '@cohort/merchants/components/buttons/Button';
import LoadingState from '@cohort/merchants/components/LoadingState';
import {useUserProperties} from '@cohort/merchants/hooks/api/UserProperties';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import type {UserSyncConfigFormValues} from '@cohort/merchants/pages/apps/app/utils';
import {SvgAppIcon} from '@cohort/shared-frontend/common/AppIcons';
import {PlusCircle} from '@phosphor-icons/react';
import {useEffect} from 'react';
import {useFieldArray, useFormContext} from 'react-hook-form';
import {Trans, useTranslation} from 'react-i18next';

type ExportUsersMappedUserPropertiesProps = {
  salesforceUserObjects: SalesforceUserObject[];
};
const ExportUsersMappedUserProperties: React.FC<ExportUsersMappedUserPropertiesProps> = ({
  salesforceUserObjects,
}) => {
  const merchant = useCurrentMerchant();

  const {t} = useTranslation('app-salesforce', {
    keyPrefix: 'sync.exportUsersMappedUserProperties',
  });

  const {data: userProperties, isFetched: isUserPropertiesFetched} = useUserProperties(
    merchant.id,
    {orderBy: 'name'}
  );

  const {control, watch} =
    useFormContext<Extract<UserSyncConfigFormValues, {appId: 'salesforce'}>>();

  const isExportEnabled = watch('userExportEnabled');
  const userExportConfig = watch('userExportConfig');

  const selectedObject = getSalesforceObjectByName(
    salesforceUserObjects,
    userExportConfig?.salesforceObjectName ?? ''
  );

  const {append: addUserProperty, replace: replaceUserProperties} = useFieldArray({
    control,
    name: 'userExportConfig.userProperties',
  });

  useEffect(() => {
    if (userExportConfig?.userProperties.length === 0) {
      replaceUserProperties([buildDefaultMappedUserProperty()]);
    }
  }, [userExportConfig?.userProperties, replaceUserProperties]);

  if (!isUserPropertiesFetched) {
    return <LoadingState className="bg-white" />;
  }

  const handleAddUserProperty = (): void => {
    addUserProperty(buildDefaultMappedUserProperty());
  };

  return (
    <div className="flex flex-col gap-2">
      <div className="flex gap-1">
        <Trans
          i18nKey="sync.exportUsersMappedUserProperty.labelUserProperties"
          ns="app-salesforce"
          components={{optional: <span className="text-slate-500" />}}
        />
      </div>
      <div className="flex w-full">
        <div className="flex w-[344px] flex-row items-center space-x-1.5">
          <SvgAppIcon name="cohort" width={24} height={24} />
          <p className="text-sm font-normal text-slate-700">
            <Trans
              i18nKey="sync.exportUsersMappedUserProperty.labelCohortUserProperty"
              ns="app-salesforce"
              components={{bold: <span className="font-medium text-slate-700" />}}
            />
          </p>
        </div>
        <div className="flex flex-row items-center space-x-1.5">
          <SvgAppIcon name="salesforce" width={24} height={24} />
          <p className="text-sm font-normal text-slate-700">
            <Trans
              i18nKey="sync.exportUsersMappedUserProperty.labelSalesforceField"
              ns="app-salesforce"
              components={{bold: <span className="font-medium text-slate-700" />}}
            />
          </p>
        </div>
      </div>
      <div className="flex flex-col gap-2">
        {userExportConfig?.userProperties.map((userProperty, index) => {
          const selectedUserProperty =
            userProperties?.find(property => property.id === userProperty.userPropertyId) ?? null;
          const selectedField =
            selectedObject?.fields.find(field => field.name === userProperty.salesforceFieldName) ??
            null;

          const mappedUserProperty: MappedUserProperty = {
            userProperty: selectedUserProperty,
            salesforceField: selectedField,
          };

          return (
            <ExportUsersMappedUserProperty
              // eslint-disable-next-line react/no-array-index-key
              key={`field-select-${index}`}
              index={index}
              salesforceUserObjects={salesforceUserObjects}
              userProperties={userProperties}
              mappedUserProperty={mappedUserProperty}
            />
          );
        })}
      </div>
      <div className="mt-2">
        <Button
          variant="secondary"
          onClick={handleAddUserProperty}
          disabled={!isExportEnabled || selectedObject === undefined}
        >
          <PlusCircle size={20} className="mr-2" />
          {t('addUserPropertyButton')}
        </Button>
      </div>
    </div>
  );
};

export default ExportUsersMappedUserProperties;
