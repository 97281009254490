import type {SyncConfigComponentProps} from '@cohort/merchants/apps';
import {useSalesforceUserObjects} from '@cohort/merchants/apps/salesforce/actions';
import ExportUsersExternaiIdFieldSelect from '@cohort/merchants/apps/salesforce/sync/ExportUsersExternaiIdFieldSelect';
import ExportUsersMappedUserProperties from '@cohort/merchants/apps/salesforce/sync/ExportUsersMappedUserProperties';
import {
  getExternalIdFieldsFromObject,
  getSalesforceObjectByName,
} from '@cohort/merchants/apps/salesforce/sync/utils';
import {RadioCards} from '@cohort/merchants/components/form/RadioCards';
import LoadingState from '@cohort/merchants/components/LoadingState';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import type {UserSyncConfigFormValues} from '@cohort/merchants/pages/apps/app/utils';
import {useEffect} from 'react';
import {useFormContext} from 'react-hook-form';
import {useTranslation} from 'react-i18next';

type ExportUsersConfigProps = SyncConfigComponentProps;
const ExportUsersConfig: React.FC<ExportUsersConfigProps> = ({connection}) => {
  const merchant = useCurrentMerchant();

  const {setValue, register, control, watch} =
    useFormContext<Extract<UserSyncConfigFormValues, {appId: 'salesforce'}>>();

  const {t} = useTranslation('app-salesforce', {
    keyPrefix: 'sync.exportUsersConfig',
  });

  const {data: getUserObjectsResponse, isFetched: isSalesforceUserObjectsFetched} =
    useSalesforceUserObjects(merchant.id, connection.id);
  const salesforceUserObjects = getUserObjectsResponse?.output.objects ?? [];

  const isExportEnabled = watch('userExportEnabled');
  const userExportConfig = watch('userExportConfig');
  const userSyncConfig = watch('userSyncConfig');

  const selectedObject = getSalesforceObjectByName(
    salesforceUserObjects,
    userSyncConfig?.salesforceObjectName ?? ''
  );

  useEffect(() => {
    if (
      !isExportEnabled ||
      selectedObject === undefined ||
      userSyncConfig?.salesforceObjectName === undefined
    ) {
      return;
    }

    setValue('userExportConfig.salesforceObjectName', userSyncConfig.salesforceObjectName);
    setValue('userExportConfig.emailFieldName', userSyncConfig.emailFieldName);

    const externalIdFields = getExternalIdFieldsFromObject(selectedObject);

    if (!externalIdFields.some(field => field.name === userExportConfig?.externalIdFieldName)) {
      setValue('userExportConfig.externalIdFieldName', '');
      return;
    }
  }, [
    setValue,
    selectedObject,
    isExportEnabled,
    userSyncConfig?.salesforceObjectName,
    userSyncConfig?.emailFieldName,
    userExportConfig?.externalIdFieldName,
  ]);

  if (!isSalesforceUserObjectsFetched) {
    return <LoadingState className="bg-white" />;
  }

  return (
    <div className="flex flex-col space-y-4">
      <RadioCards
        name="userExportConfig.createSalesforceUsers"
        label={t('labelUserGroup')}
        register={register}
        control={control}
        disabled={!isExportEnabled}
        options={[
          {label: t('labelAllUsers'), value: 'true'},
          {label: t('labelExistingSalesforceUsers'), value: 'false'},
        ]}
        onChange={e => {
          setValue('userExportConfig.createSalesforceUsers', e.target.value === 'true');
        }}
        preventFieldOnChange
      />
      <ExportUsersExternaiIdFieldSelect salesforceUserObjects={salesforceUserObjects} />
      <ExportUsersMappedUserProperties salesforceUserObjects={salesforceUserObjects} />
    </div>
  );
};

export default ExportUsersConfig;
