/* eslint-disable @typescript-eslint/naming-convention */
import type {ConnectionADto} from '@cohort/admin-schemas/connection';
import type {ConnectionEditComponentProps} from '@cohort/merchants/apps';
import Button from '@cohort/merchants/components/buttons/Button';
import ConnectionHeader from '@cohort/merchants/components/connections/ConnectionHeader';
import OAuthFlowHandler from '@cohort/merchants/components/connections/OAuthFlowHandler';
import ErrorState from '@cohort/merchants/components/ErrorState';
import {notify} from '@cohort/merchants/hooks/toast';
import type {AppId} from '@cohort/shared/apps';
import {getAppSpec} from '@cohort/shared/apps';
import type {CohortErrorCode} from '@cohort/shared/schema/common/errors';
import {CheckCircle, CirclesThreePlus, WarningCircle} from '@phosphor-icons/react';
import React, {Fragment, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {match, P} from 'ts-pattern';

export type CustomOAuthErrorMessage = (cause: CohortErrorCode) => string | undefined;

export type PreConnectionConfigComponentProps = {
  connection?: ConnectionADto;
  onConfigFinished: (config: Record<string, unknown>) => void;
};

export type PostConnectionConfigComponentProps = {
  connection: ConnectionADto;
  onConfigFinished: (connection: ConnectionADto) => void;
};

type OAuthEditConnectionComponentProps = ConnectionEditComponentProps & {
  appId: AppId;
  customOauthErrorMessage?: CustomOAuthErrorMessage;
  PreConnectionConfigComponent?: React.FC<PreConnectionConfigComponentProps>;
  PostConnectionConfigComponent?: React.FC<PostConnectionConfigComponentProps>;
};

const OAuthEditConnectionComponent: React.FC<OAuthEditConnectionComponentProps> = ({
  existingConnection,
  onClose,
  appId,
  customOauthErrorMessage,
  PreConnectionConfigComponent,
  PostConnectionConfigComponent,
}) => {
  const [oauthStarted, setOauthStarted] = useState(false);
  const [reconnect, setReconnect] = useState(false);
  const [oauthError, setOauthError] = useState(false);

  const [connection, setConnection] = useState<ConnectionADto | undefined>(existingConnection);
  const [preConfig, setPreConfig] = useState<Record<string, unknown> | undefined>(undefined);
  const appSpec = getAppSpec(appId);

  const {t} = useTranslation('components', {
    keyPrefix: 'connections.oauthEditConnectionComponent',
  });

  const handleOauthFlowCompleted = (connection: ConnectionADto | null): void => {
    if (connection) {
      setConnection(connection);
      onClose?.(connection);
      notify('success', t('connectionSuccessful', {appName: appSpec.name}));
    } else {
      setOauthError(true);
    }
    setOauthStarted(false);
  };

  const ReconnectButton: React.FC = () => (
    <Button
      type="submit"
      variant="secondary"
      onClick={() => {
        if (PreConnectionConfigComponent) {
          setReconnect(true);
        } else {
          setOauthStarted(true);
        }
      }}
    >
      {t('buttonReconnect')}
    </Button>
  );

  const content = match({
    connection,
    oauthStarted,
    reconnect,
    oauthError,
    preConfig,
    PreConnectionConfigComponent,
    PostConnectionConfigComponent,
  })
    .with(
      {connection: undefined, PreConnectionConfigComponent: undefined, oauthStarted: false},
      () => (
        <ConnectionHeader
          appId={appId}
          title={
            <span className="text-base font-medium">
              {t('titleConnect', {appName: appSpec.name})}
            </span>
          }
          actions={
            <Button type="submit" className="flex gap-2" onClick={() => setOauthStarted(true)}>
              <CirclesThreePlus className="h-5 w-5" />
              {t('buttonConnect')}
            </Button>
          }
        />
      )
    )
    .with(
      {
        connection: undefined,
        PreConnectionConfigComponent: P.not(undefined),
        oauthStarted: false,
      },
      {
        connection: P.not(undefined),
        PreConnectionConfigComponent: P.not(undefined),
        reconnect: true,
      },
      ({PreConnectionConfigComponent}) => (
        <Fragment>
          <ConnectionHeader
            appId={appId}
            title={
              <span className="text-base font-medium">
                {t('titleConnect', {appName: appSpec.name})}
              </span>
            }
          />
          <PreConnectionConfigComponent
            onConfigFinished={config => {
              setPreConfig(config);
              setOauthStarted(true);
              setReconnect(false);
            }}
          />
        </Fragment>
      )
    )
    .with({oauthStarted: true}, () => (
      <Fragment>
        <ConnectionHeader
          appId={appId}
          title={<span className="text-sm font-normal text-slate-500">{t('titleConnecting')}</span>}
          actions={<Button className="w-24" type="button" loading={true} />}
        />
        <OAuthFlowHandler
          appSpec={appSpec}
          onCompleted={handleOauthFlowCompleted}
          existingConnectionId={connection?.id}
          preConfig={preConfig}
          customOauthErrorMessage={customOauthErrorMessage}
        />
      </Fragment>
    ))
    .with({oauthError: true}, () => <ErrorState />)
    .with({connection: {status: 'broken'}}, () => (
      <ConnectionHeader
        appId={appId}
        title={
          <p className="flex gap-1">
            <WarningCircle className="h-5 w-5 text-red-500" />
            <span className="text-sm font-medium text-red-700">{t('titleBroken')}</span>
          </p>
        }
        actions={<ReconnectButton />}
      />
    ))
    .with({connection: {status: 'ready'}}, () => (
      <ConnectionHeader
        appId={appId}
        title={
          <p className="flex gap-1">
            <CheckCircle className="h-5 w-5 text-green-500" />
            <span className="text-sm font-medium text-green-700">{t('titleReady')}</span>
          </p>
        }
        actions={<ReconnectButton />}
      />
    ))
    .with(
      {connection: {status: 'config_needed'}, PostConnectionConfigComponent: P.not(undefined)},
      ({connection, PostConnectionConfigComponent}) => (
        <PostConnectionConfigComponent connection={connection} onConfigFinished={setConnection} />
      )
    )
    .otherwise(() => <Fragment />);

  return <div className="flex flex-col items-center justify-center space-y-4">{content}</div>;
};

export default OAuthEditConnectionComponent;
