import type {UserPropertyADto} from '@cohort/admin-schemas/userProperty';
import type {
  MappedUserProperty,
  SalesforceUserObject,
} from '@cohort/merchants/apps/salesforce/sync/utils';
import {getSalesforceObjectByName} from '@cohort/merchants/apps/salesforce/sync/utils';
import DataTypeIcon from '@cohort/merchants/components/DataTypeIcon';
import {SelectPicker} from '@cohort/merchants/components/form/select/SelectPicker';
import type {UserSyncConfigFormValues} from '@cohort/merchants/pages/apps/app/utils';
import {useFieldArray, useFormContext} from 'react-hook-form';
import {useTranslation} from 'react-i18next';

interface ExportUsersUserPropertySelectProps {
  index: number;
  salesforceUserObjects: SalesforceUserObject[];
  userProperties: UserPropertyADto[] | undefined;
  mappedUserProperty: MappedUserProperty;
}

const ExportUsersUserPropertySelect: React.FC<ExportUsersUserPropertySelectProps> = ({
  index,
  salesforceUserObjects,
  userProperties,
  mappedUserProperty,
}) => {
  const {t} = useTranslation('app-salesforce', {
    keyPrefix: 'sync.exportUsersUserPropertySelect',
  });

  const {control, watch} =
    useFormContext<Extract<UserSyncConfigFormValues, {appId: 'salesforce'}>>();

  const isExportEnabled = watch('userExportEnabled');
  const salesforceObjectName = watch('userSyncConfig.salesforceObjectName');
  const mappedUserProperties = watch('userExportConfig.userProperties');

  const selectedObject = getSalesforceObjectByName(salesforceUserObjects, salesforceObjectName);

  const {replace: replaceUserProperties} = useFieldArray({
    control,
    name: 'userExportConfig.userProperties',
  });

  const selectedUserPropertyIds = mappedUserProperties.map(
    mappedUserProperty => mappedUserProperty.userPropertyId
  );
  const userPropertyOptions = userProperties
    ?.filter(userProperty => userProperty.appId !== 'salesforce')
    .map(userProperty => ({
      label: userProperty.name,
      value: userProperty.id,
      isDisabled: selectedUserPropertyIds.includes(userProperty.id),
    }));
  const userPropertyValue =
    mappedUserProperty.userProperty !== null
      ? {
          value: mappedUserProperty.userProperty.id,
          label: mappedUserProperty.userProperty.name,
        }
      : null;

  const handleChange = (index: number, userPropertyId: string): void => {
    const updatedMappedUserProperties = [...mappedUserProperties];

    if (updatedMappedUserProperties[index] !== undefined) {
      updatedMappedUserProperties[index] = {
        userPropertyId,
        salesforceFieldName: '', // reinitialize the field when changing the user property
      };
      replaceUserProperties(updatedMappedUserProperties);
    }
  };

  return (
    <SelectPicker
      name={`user-property-${index}`}
      placeholder={t('placeholder')}
      formatOptionLabel={data => {
        const dataType = userProperties?.find(property => property.id === data.value)?.dataType;
        return (
          <div className="flex items-center gap-2">
            {dataType && <DataTypeIcon dataType={dataType} />}
            <span>{data.label}</span>
          </div>
        );
      }}
      options={userPropertyOptions}
      value={userPropertyValue}
      isDisabled={!isExportEnabled || selectedObject === undefined}
      onChange={option => {
        handleChange(index, String(option?.value));
      }}
    />
  );
};

export default ExportUsersUserPropertySelect;
